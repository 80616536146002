import type { ComponentType } from "react"
import dynamic from "next/dynamic"
import { z } from "zod"

import type { ExplicitAny, ObjectValues } from "~/@types/generics"

export const slices = {
  hero_main: dynamic(() => import("./Heroes/HeroMain")),
  hero_dyptic: dynamic(() => import("./Heroes/HeroDyptic")),
  hero_framed: dynamic(() => import("./Heroes/HeroFramed")),
  edito_text_image: dynamic(() => import("./EditoTextImage")),
  edito_text_image_tight: dynamic(() => import("./EditoTextImageTight")),
  edito_title_cta: dynamic(() => import("./EditoTitleCta")),
  grid_cards_triptych: dynamic(() => import("./GridCardsTriptych")),
  grid_cards_triptych_full_size: dynamic(() => import("./GridCardsTriptychFullSize")),
  edito_cards_slider: dynamic(() => import("./EditoCardsSlider")),
  product_slider: dynamic(() => import("./ProductSlider")),
  edito_read_more: dynamic(() => import("./EditoReadMore")),
  product_card_image_diptych: dynamic(() => import("./ProductCardImageDiptych")),
  edito_two_product_card: dynamic(() => import("./EditoTwoProductCard")),
  double_image: dynamic(() => import("./DoubleImage")),
  three_product_cards: dynamic(() => import("./ThreeProductCards")),
  four_product_cards: dynamic(() => import("./FourProductCards")),
  edito_text_framed_airy: dynamic(() => import("./EditoTextFramedAiry")),
  edito_text_reasurance_image: dynamic(() => import("./EditoTextReasuranceImage")),
  edito_text: dynamic(() => import("./EditoText")),
  edito_text_framed: dynamic(() => import("./EditoTextFramed")),
  edito_text_list_image: dynamic(() => import("./EditoTextListImage")),
  partners_slider: dynamic(() => import("./PartnersSlider")),
  edito_cards_zoom_slider: dynamic(() => import("./EditoCardsZoomSlider")),
  edito_wysiwyg: dynamic(() => import("./EditoWYSIWYG")),
  edito_blockquote: dynamic(() => import("./EditoBlockquote")),
  edito_full_image: dynamic(() => import("./EditoFullImage")),
  edito_half_images: dynamic(() => import("./EditoHalfImages")),
  blog_summary: dynamic(() => import("./BlogSummary")),
  separator: dynamic(() => import("./Separator")),
  cloud_navigation: dynamic(() => import("./CloudNavigation")),
} satisfies Record<Slice, ComponentType<ExplicitAny>>

const SLICES = {
  HERO_MAIN: "hero_main",
  HERO_DYPTIC: "hero_dyptic",
  HERO_FRAMED: "hero_framed",
  EDITO_TEXT_IMAGE: "edito_text_image",
  EDITO_TEXT_IMAGE_TIGHT: "edito_text_image_tight",
  EDITO_CARDS_SLIDER: "edito_cards_slider",
  EDITO_TITLE_CTA: "edito_title_cta",
  EDITO_READ_MORE: "edito_read_more",
  GRID_CARDS_TRIPTYCH: "grid_cards_triptych",
  GRID_CARDS_TRIPTYCH_FULL_SIZE: "grid_cards_triptych_full_size",
  PRODUCT_SLIDER: "product_slider",
  PRODUCT_CARD_IMAGE_DIPTYCH: "product_card_image_diptych",
  EDITO_TWO_PRODUCT_CARD: "edito_two_product_card",
  DOUBLE_IMAGE: "double_image",
  THREE_PRODUCT_CARDS: "three_product_cards",
  FOUR_PRODUCT_CARDS: "four_product_cards",
  EDITO_TEXT_FRAMED_AIRY: "edito_text_framed_airy",
  EDITO_TEXT_REASURANCE_IMAGE: "edito_text_reasurance_image",
  EDITO_TEXT: "edito_text",
  EDITO_TEXT_FRAMED: "edito_text_framed",
  EDITO_TEXT_LIST_IMAGE: "edito_text_list_image",
  PARTNERS_SLIDER: "partners_slider",
  EDITO_CARDS_ZOOM_SLIDER: "edito_cards_zoom_slider",
  EDITO_WYSIWYG: "edito_wysiwyg",
  EDITO_BLOCKQUOTE: "edito_blockquote",
  EDITO_FULL_IMAGE: "edito_full_image",
  EDITO_HALF_IMAGES: "edito_half_images",
  BLOG_SUMMARY: "blog_summary",
  SEPARATOR: "separator",
  CLOUD_NAVIGATION: "cloud_navigation",
} as const

export type Slice = ObjectValues<typeof SLICES>

export function defaultSliceSchema<TKey extends Slice>(component: TKey) {
  return z.object({
    component: z.literal<TKey>(component),
    isFirstSlice: z.boolean(),
    isLastSlice: z.boolean(),
    locale: z.string().nullish(),
  })
}
