"use client"

import { useState } from "react"
import clsx from "clsx"
import useMeasure from "react-use-measure"

import type { InferReturn } from "~/@types/generics"
import InlineCta from "~/components/ui/InlineCta"
import RichText from "~/components/ui/RichText"
import { Stack } from "~/components/abstracts/Stack"
import { editoReadMoreSliceSchema } from "~/components/slices/EditoReadMore/_data/schema"
import serializeEditoReadMoreSlice from "~/components/slices/EditoReadMore/_data/serializer"
import { WithSafeSliceData } from "~/components/slices/WithSafeSliceData"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

export type EditoReadMoreProps = InferReturn<typeof serializeEditoReadMoreSlice>

function EditoReadMore({ title, text, readMoreLabel, readLessLabel, maxHeight }: EditoReadMoreProps) {
  const [contentRef, contentBound] = useMeasure()
  const [seeMore, setSeeMore] = useState(false)

  const needSeeMoreButton = contentBound?.height > maxHeight

  return (
    <div className={clsx(css.EditoReadMore)} data-comp="Slices/EditoReadMore" ref={contentRef}>
      <Stack direction={"column"} gap={10} className={css.wrapper}>
        <RichText className={css.title} render={title} />

        <div>
          <RichText
            className={clsx(css.content, {
              [css.seeMore]: needSeeMoreButton ? seeMore : false,
              [css.contentAfter]: needSeeMoreButton ? !seeMore : false,
            })}
            render={text}
            style={{ maxHeight: `${maxHeight}px` }}
          />
        </div>

        {/* Condition in styles to fix a bug in Chrome - IOS, 
        We are in the end of page when page change  */}
        <InlineCta
          onClick={() => setSeeMore(!seeMore)}
          className={clsx(css.cta, sprinkles({ display: needSeeMoreButton ? "block" : "none" }))}
        >
          {!seeMore ? readMoreLabel : readLessLabel}
        </InlineCta>
      </Stack>
    </div>
  )
}

export default WithSafeSliceData(
  function (props) {
    const serializedProps = serializeEditoReadMoreSlice(props)

    return <EditoReadMore {...serializedProps} />
  },
  editoReadMoreSliceSchema,
  "EditoReadMoreSlice"
)
